import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links";

function Sitemap() {
  return (
    <Layout>
      <SEO
        title="Sitemap"
        description="Navigate our website with ease using the Sitemap. Find a comprehensive list of all pages and resources, organized for quick access and better user experience."
      />
      <div className="text-justify">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h1>Sitemap</h1>
              </div>
              <div className="content-section">
                <ul>
                  <li><AnchorLink to="/">Home</AnchorLink></li>
                  <li><AnchorLink to="/blogs">Blogs</AnchorLink></li>
                  <li><AnchorLink to="/#about">About</AnchorLink></li>
                  <li><AnchorLink to="/#portfolio">Our Products</AnchorLink></li>
                  <li><AnchorLink to="/#services"><strong>Services</strong></AnchorLink>
                    <ul>
                      <li><AnchorLink to="/#services"><strong>Testing</strong></AnchorLink>
                      <ul>
                        <li><AnchorLink to="http://localhost:8000/manual-testing/">Manual Testing</AnchorLink></li>
                        <li><AnchorLink to="http://localhost:8000/automation-testing/">Automation Testing</AnchorLink></li>
                        <li><AnchorLink to="http://localhost:8000/performance-testing/">Performance Testing</AnchorLink></li>
                        <li><AnchorLink to="http://localhost:8000/accessibility-testing/">Accessibility Testing</AnchorLink></li>
                        <li><AnchorLink to="http://localhost:8000/security-testing/">Security Testing</AnchorLink></li>
                        <li><AnchorLink to="http://localhost:8000/penetration-testing/"><strong>Penetration Testing</strong></AnchorLink>
                          <ul>
                            <li><AnchorLink to="http://localhost:8000/android-penetration-testing/">Android Penetration Testing</AnchorLink></li>
                            <li><AnchorLink to="http://localhost:8000/api-penetration-testing/">API Penetration Testing</AnchorLink></li>
                            <li><AnchorLink to="http://localhost:8000/cloud-penetration-testing/">Cloud Penetration Testing</AnchorLink></li>
                            <li><AnchorLink to="http://localhost:8000/network-penetration-testing/">Network Penetration Testing </AnchorLink></li>
                          </ul>
                        </li>
                      </ul>
                      </li>
                      <li><AnchorLink to="http://localhost:8000/website-development/">Web Development</AnchorLink></li>
                      <li><AnchorLink to="http://localhost:8000/mobile-development/">Mobile Development</AnchorLink></li>
                      <li><AnchorLink to="http://localhost:8000/accessibility-remediation/">Accessibility Remediation</AnchorLink></li>
                      <li><AnchorLink to="http://localhost:8000/devops/">DevOps</AnchorLink></li>
                      <li><AnchorLink to="http://localhost:8000/secops/">SecOps</AnchorLink></li>
                      <li><AnchorLink to="http://localhost:8000/digital-marketing/">Digital Marketing</AnchorLink></li>
                      <li><AnchorLink to="http://localhost:8000/ai-development/">AI Development</AnchorLink></li>
                    </ul>
                  </li>
                  <li><AnchorLink to="/#clients">Our Clients</AnchorLink></li>
                  <li><AnchorLink to="/careers/">Careers</AnchorLink></li>
                  <li><AnchorLink to="/contact/">Contact Us</AnchorLink></li>
                  <li><AnchorLink to="/accessibility-statement/" gatsbyLinkProps={{ target: "_blank", rel: "noopener noreferrer" }}>Accessibility Statement</AnchorLink></li>
                  <li><AnchorLink to="/privacy-policy/" gatsbyLinkProps={{ target: "_blank", rel: "noopener noreferrer" }}>Privacy Policy</AnchorLink></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sitemap
